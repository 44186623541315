// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "~bootstrap/scss/functions";

// 2. Include any default variable overrides here
$primary: #2daf32;
$secondary: #eefade;

// 3. Include remainder of required Bootstrap stylesheets
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

@import "~bootstrap/scss/helpers";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/utilities/api";
