@import "../../../styles/variables.scss";

.productsGrid {
  width: calc(100vw - 80px) !important;
  height: calc(100vh - 80px) !important;
  padding: 40px;
  margin: 40px;
  border: 6px solid $primarygreen;
  position: relative;

  .category-name {
    font-weight: bold;
    font-size: 30px;
    color: $primarygreen;
    text-transform: uppercase;
    position: absolute;
    top: -50px;
    background-color: #fff;
    padding: 20px;
  }
}
