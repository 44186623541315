.main-layout {
  .main-sider {
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
  }
  .main-header {
    position: fixed;
    z-index: 1;
    box-shadow: 0px 0px 3px #ccc;
  }
  .content-layout {
    transition: all 0.2s;
  }
  .outlet-layout {
    margin: 80px 16px 0;
    overflow: initial;
    background-color: #fff;
    padding: 16px;
  }
}
