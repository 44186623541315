.drawer-view .ant-drawer-body {
  padding: 0;
}

.pos-product .ant-card .ant-card-body {
  padding: 10px;
}

.pos-product .ant-card .ant-card-cover {
  margin: 0;
}

.pos-product .ant-card-meta-title {
  font-size: 11px;
  white-space: initial;
  text-align: center;
  font-weight: 100;
}

.pos-product .cost {
  display: none;
}

.pos-product .mrp {
  font-weight: 500;
}

.pos-product .ant-card:hover .cost {
  display: block;
}

.product-list .ant-col {
  height: 100%;
}
