.no-header-table table thead {
  display: none;
}

.no-header-table table tr td {
  border: none;
}

.drawer-view .ant-drawer-body {
  padding-top: 0;
  padding-bottom: 0;
}

.supplier-info {
  height: 150px;
}
